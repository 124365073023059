import React from "react"
import Seo from "../../components/seo"
import Layout from "../../Layout"
import Breadcrumbs from "../../components/Breadcrumbs"
import Image1 from "./images/joy1.jpg"
import Image2 from "./images/joy2.jpg"
import Image3 from "./images/joy3.jpg"
import Image4 from "./images/joy4.jpg"
import Image5 from "./images/joy5.jpeg"
import Image6 from "./images/joy6.jpg"
import FarmImage1 from "./images/farm1.jpg"
import FarmImage2 from "./images/farm2.jpg"

const images = [Image1, Image2, Image3, Image4, Image5, Image6]

const joyweedBenefits = [
  {
    text: "Rich in Nutrients",
    details:
      "High in vitamins A, C, and K, as well as calcium, iron, and magnesium.",
  },
  {
    text: "Boosts Immunity",
    details: "Packed with antioxidants that support a strong immune system.",
  },
  {
    text: "Anti-Inflammatory",
    details: "Helps reduce inflammation in the body.",
  },
  {
    text: "Promotes Healthy Digestion",
    details: "High fiber content aids in digestion and prevents constipation.",
  },
  {
    text: "Detoxifying",
    details: "Supports liver function and helps detoxify the body.",
  },
]

const JoyweedPage = () => (
  <Layout>
    <Seo title="Sessile Joyweed" />
    <Breadcrumbs title="Sessile Joyweed" />
    <div className="container mt-5">
      <div className="row justify-content-center">
        <div className="col-md-10 col-lg-8">
          <style>
            {`
              .fixed-size { width: 100px; height: 100px; object-fit: cover; margin: 3px; }
              .narrow-paragraph { max-width: 700px; margin: 0 auto; }
              .custom-table { width: 100%; margin-top: 20px; border-collapse: collapse; }
              .custom-table th, .custom-table td { border: 1px solid #ddd; padding: 8px; }
              .custom-table th { background-color: #f2f2f2; text-align: left; }
              .custom-table .green-header { background-color: #d4edda; }
              .margin-bottom { margin-bottom: 50px; }
              .farm-description { display: flex; align-items: center; margin-bottom: 15px; }
              .farm-description img { margin-right: 15px; width: 100px; height: 100px; object-fit: cover; }
              
              @media (max-width: 768px) {
                .farm-description { flex-direction: column; }
                .farm-description img { margin-bottom: 10px; }
                .custom-table th, .custom-table td { padding: 6px; }
                .narrow-paragraph p { font-size: 14px; }
              }

              @media (max-width: 576px) {
                .row > .col-4, .col-md-4 { flex: 0 0 100%; max-width: 100%; }
                .fixed-size { width: 100%; }
                .container { padding-left: 15px; padding-right: 15px; }
              }
            `}
          </style>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="row">
                {images.slice(0, 3).map((image, index) => (
                  <div className="col-4" key={index}>
                    <img
                      src={image}
                      alt={`Joyweed field ${index + 1}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-2">
                {images.slice(3).map((image, index) => (
                  <div className="col-4" key={index + 3}>
                    <img
                      src={image}
                      alt={`Joyweed field ${index + 4}`}
                      className="img-thumbnail fixed-size"
                    />
                  </div>
                ))}
              </div>
              <div className="row mt-3 narrow-paragraph">
                <div className="col-12">
                  <p>
                    Sessile Joyweed (Alternanthera sessilis), also known as
                    water spinach or moringa-leafed joyweed, is a leafy green
                    plant commonly found in tropical and subtropical regions.
                    Known for its vibrant green leaves and fast-growing nature,
                    this plant is highly valued for both its nutritional
                    benefits and medicinal properties. It thrives in
                    well-drained, moist soils and is often found growing near
                    water sources, making it a popular choice for organic
                    farming practices in these regions.
                  </p>
                  <p>
                    Though not as commonly found in mainstream grocery stores,
                    sessile joyweed has gained attention in the health and
                    wellness community for its diverse health benefits and
                    adaptability in organic gardening. Choosing organic sessile
                    joyweed means you’re supporting sustainable farming methods
                    that prioritize the health of the soil, environment, and
                    your body. Organic cultivation avoids the use of synthetic
                    pesticides and fertilizers, ensuring that you’re consuming
                    pure, nutrient-dense leaves without harmful chemicals.
                  </p>
                </div>
              </div>
              <h3>
                <strong>Nutrition Table</strong>
              </h3>
              <table className="custom-table margin-bottom">
                <thead>
                  <tr>
                    <th className="green-header">Nutrient</th>
                    <th className="green-header">
                      Amount (per 100 grams, raw)
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Calories</td>
                    <td>32 kcal</td>
                  </tr>
                  <tr>
                    <td>Protein</td>
                    <td>3.0 g</td>
                  </tr>
                  <tr>
                    <td>Fat</td>
                    <td>0.5 g</td>
                  </tr>
                  <tr>
                    <td>Carbohydrates</td>
                    <td>5.0 g</td>
                  </tr>
                  <tr>
                    <td>Fiber</td>
                    <td>2.0 g</td>
                  </tr>
                  <tr>
                    <td>Sugars</td>
                    <td>1.2 g</td>
                  </tr>
                  <tr>
                    <td>Vitamin A</td>
                    <td>34% of DV</td>
                  </tr>
                  <tr>
                    <td>Vitamin C</td>
                    <td>17% of DV</td>
                  </tr>
                  <tr>
                    <td>Vitamin K</td>
                    <td>10% of DV</td>
                  </tr>
                  <tr>
                    <td>Calcium</td>
                    <td>53 mg</td>
                  </tr>
                  <tr>
                    <td>Iron</td>
                    <td>2.1 mg</td>
                  </tr>
                  <tr>
                    <td>Magnesium</td>
                    <td>48 mg</td>
                  </tr>
                  <tr>
                    <td>Potassium</td>
                    <td>260 mg</td>
                  </tr>
                </tbody>
              </table>
              <p>DV = Daily Value based on a 2,000-calorie diet</p>
              <h3>
                <strong>Our Organic Farms</strong>
              </h3>
              <div className="farm-description">
                <img
                  src={FarmImage1}
                  alt="Organic Farm 1"
                  className="img-thumbnail"
                />
                <p>Farm at Nuwara Eliya Control Union Certified</p>
              </div>
              <div className="farm-description">
                <img
                  src={FarmImage2}
                  alt="Organic Farm 2"
                  className="img-thumbnail"
                />
                <p>Farm at Chilaw PGS Certified</p>
              </div>
            </div>
            <div className="col-12 col-md-4 ml-auto">
              <h2>Health Benefits of Sessile Joyweed</h2>
              <ul style={{ listStyleType: "none", paddingLeft: "0" }}>
                {joyweedBenefits.map((benefit, index) => (
                  <li key={index}>
                    <span style={{ color: "green" }}>✅</span>
                    <span style={{ color: "black" }}>
                      <strong>{benefit.text}:</strong> {benefit.details}
                    </span>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
)

export default JoyweedPage
